export const NumberOne = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="93"
    height="161.161"
    viewBox="0 0 93 161.161"
  >
    <path
      d="M22.5,230.5q-1.75.75-3.375-2A10.731,10.731,0,0,1,17.5,223q0-2,1.25-2.5,4.25-1.75,10.125-4.125T42.5,211.25q7.25-19.5,18.125-48.875T86,93.75a180.326,180.326,0,0,1-34,18,3.482,3.482,0,0,1-1.5.25q-2,0-2-2.75A2.423,2.423,0,0,1,50.25,107q15.5-6.5,42.25-30.5l1-2.5q1.5-4.5,9.75-4.5,7.25,0,7.25,3.5a1.7,1.7,0,0,1-.5,1.25L52.75,209.75a155.082,155.082,0,0,1,17.375-2.625q9.125-.875,19.125-.875a2.5,2.5,0,0,1,0,5,107.448,107.448,0,0,0-34.5,6Q51,218.5,43,221.75T22.5,230.5Z"
      transform="translate(-17.5 -69.5)"
      fill="#fff"
    />
  </svg>
);

export const NumberTwo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="167.25"
    height="161"
    viewBox="0 0 167.25 161"
  >
    <path
      d="M128,234.75a95.021,95.021,0,0,1-21.75-3q-12.5-3-28.25-8.5-16-5.75-26.5-8.625T36,211.75q-13.75,6-22.125,9.125T2.5,224q-6,0-6-4.5,0-3.5,3.75-7.5A19.543,19.543,0,0,1,9,206.5q7.5-2.25,19.75-2.25h4.125a39.619,39.619,0,0,1,4.375.25q26.5-16.25,44.625-28.5t27.875-20.25q8-6.75,13.875-12.625A83.629,83.629,0,0,0,133,132.25a57.056,57.056,0,0,0,11.75-29.75q1.5-18-19-18Q109.5,84.5,86.5,95q-23.5,10.5-35,22.5a3.086,3.086,0,0,1-1.75.75q-2.25,0-2.25-2.75a3.759,3.759,0,0,1,1.25-2Q66.5,97.75,93.25,85.75q27-12,46.25-12,24.25,0,24.25,19.25,0,17.75-19.5,39.75-4.75,5.25-12.75,12.5T112.5,162a289.951,289.951,0,0,1-29.875,21.5Q65,194.75,42,207.25a116.779,116.779,0,0,1,16.75,3q10.5,2.5,25.25,7.5,27.5,9,44.5,9,12.75,0,20.625-5t7.875-12q0-10.25-17.5-16.5-1.5-.5-1.5-1.5,0-2.25,3.5-1,22,6,22,20,0,10-10.5,17.25Q142.5,234.75,128,234.75Z"
      transform="translate(3.5 -73.75)"
      fill="#fff"
    />
  </svg>
);

export const NumberThree = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="163.598"
    height="155.5"
    viewBox="0 0 163.598 155.5"
  >
    <path
      d="M64.5,227.5q-26.25,0-42.5-8.5Q4,209.5,4,193.25A34.77,34.77,0,0,1,6.25,181.5q4-11,21-19,14.5-6.5,26-6.5,2.5,0,2.5,1.5,0,1.75-2.5,1.75-8.25,0-19.5,7.5-12.5,8.5-13.75,18a8.219,8.219,0,0,0-.25,2v2q0,23.25,29,31.25A84.2,84.2,0,0,0,71,223q19.75,0,40-9,35.75-16.25,42.75-49.25a24.409,24.409,0,0,0,.375-2.75q.125-1.5.125-2.75,0-12-12.75-18-10.75-5-27-5-15,0-24,3.75a11,11,0,0,1-4.5,1q-4.5,0-4.5-4.5,0-6,5.75-6,21.5,0,39-7.25,22.5-9.5,22.5-27.5,0-15-17.5-15-12,0-29,6.75Q86.5,94,76.75,101.25a4.385,4.385,0,0,1-1.25.5q-2,0-2-4.25,0-5.25,3-6.25,6.5-2,16.25-13.5a2.162,2.162,0,0,1,1.75-1q1.25,0,1.25,1.5,0,.75-4.25,6.5Q118.25,72,136.25,72q24,0,27,22.25a4.719,4.719,0,0,1,.25,1.5V97q0,20.5-41.25,33,19.5,1.5,31.75,8.75,14.75,8.5,13.5,24.75-2.25,29.5-35.75,48Q102.25,227.5,64.5,227.5Z"
      transform="translate(-4 -72)"
      fill="#fff"
    />
  </svg>
);

export const NumberFour = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="162.25"
    height="160.25"
    viewBox="0 0 162.25 160.25"
  >
    <path
      d="M99.25,229.25a3.109,3.109,0,0,1-2.25-1A22.265,22.265,0,0,1,92.25,215a218.656,218.656,0,0,1,1.375-25.125A146.955,146.955,0,0,1,98,167.25q-21-5.5-34.75-5.5-10,0-28.5,6-18.75,6-20,6a5.666,5.666,0,0,1-4.5-2.625,9.111,9.111,0,0,1-2-5.625q0-5,4.25-6a46.125,46.125,0,0,1,6.375-1.75Q22.5,157,26.5,156q74-40.75,118.75-82.5L147,70.25q.75-1.25,4-1.25a14.017,14.017,0,0,1,6.125,1.375Q160,71.75,159.25,73.25l-26,44.5a505.768,505.768,0,0,0-24,45.75,179.881,179.881,0,0,0,18,4.625,76.215,76.215,0,0,0,14.5,1.625,21.374,21.374,0,0,0,12.75-4.125,13.234,13.234,0,0,0,5.75-11.375,21.114,21.114,0,0,0-1-5.875,20.855,20.855,0,0,1-1-4.375,1.1,1.1,0,0,1,1.25-1.25A4.24,4.24,0,0,1,163,145q7.5,9.75,7.5,16.5,0,13.5-28.75,13.5a92.968,92.968,0,0,1-15.5-1.5q-8.75-1.5-19.75-4a135.336,135.336,0,0,0-6.875,22.625A111.635,111.635,0,0,0,97.25,215q0,4.5,4,10a4.029,4.029,0,0,1,.5,1.75A2.21,2.21,0,0,1,99.25,229.25Zm.75-69a192.446,192.446,0,0,1,10.125-24.75q6.625-13.75,16.625-30.5l7-11.75q-29.5,24.5-92.5,60.75,5.75-.5,11-1t10-.5a79.886,79.886,0,0,1,16.375,2Q88,156.5,100,160.25Z"
      transform="translate(-8.25 -69)"
      fill="#fff"
    />
  </svg>
);

export const NumberFive = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="182.5"
    height="164"
    viewBox="0 0 182.5 164"
  >
    <path
      d="M64.75,86q-1.25,1-2.125-2.125a24.466,24.466,0,0,1-.875-6.625q0-3.5,1-4.5a23.645,23.645,0,0,1,12.5-5.25,26.855,26.855,0,0,1,4.5-.25H88q13.25,0,36,6.75,23.75,6.5,35.75,6.5a22.4,22.4,0,0,0,11-3.25q7.75-4,6.75-10.75v-1q0-2.25,2-2.25,1,0,1.75,1.5a13.6,13.6,0,0,1,1.75,6.5q0,7.25-9.75,11.5a40.821,40.821,0,0,1-14,2.75q-6.25,0-15-.75t-20-2.5q-11-1.75-19.75-2.5T89.25,79Q72.5,79,64.75,86ZM46,227.25a70.689,70.689,0,0,1-16.875-1.875A67.53,67.53,0,0,1,15,220.25Q.5,212.5.5,199q0-11.5,11.5-24.5,2.25-2.5,4-2.5a1.326,1.326,0,0,1,1.5,1.5,5.255,5.255,0,0,1-1.75,3.75q-6.5,7.75-6.5,15,0,11.5,14,20.25a55.583,55.583,0,0,0,30,8.25q30,0,56.5-21.75,29-23,29-51.75,0-15.75-17.5-22.75A61.7,61.7,0,0,0,97,119.75q-21,0-35.75,8Q56.25,131,51,134l-10.5,6a3.482,3.482,0,0,1-1.5.25q-2,0-2-3.25,0-3,2.25-4l23-37.75Q64,92.75,73,92.75q5.25,0,4,1.5L52,128q31.5-13.75,45.75-13.75,57.75,0,57.75,30.5,0,34.25-36.25,59Q84.75,227.25,46,227.25Z"
      transform="translate(-0.5 -63.25)"
      fill="#fff"
    />
  </svg>
);

export const NumberSix = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="152.25"
    height="156.75"
    viewBox="0 0 152.25 156.75"
  >
    <path
      d="M54,229.75q-23.25,0-35-10.75Q8,209.25,8,192.25q0-20.75,15.25-43a139.01,139.01,0,0,1,42-39.75Q83.5,98.5,98.5,90.875A208.657,208.657,0,0,1,125.25,79.25q-8.5-1.75-8.5-3.5,0-2.75,2.5-2a55.569,55.569,0,0,0,12.5,1.5A40.277,40.277,0,0,0,144,73.5q1.25-.25,2.375-.375A19.287,19.287,0,0,1,148.5,73a12.467,12.467,0,0,1,8.375,2.875q3.375,2.875,3.375,5.875A1.958,1.958,0,0,1,158.5,84a248.945,248.945,0,0,0-39.875,12A378.357,378.357,0,0,0,73.5,117.25,124.07,124.07,0,0,0,32.25,153.5Q16,175.75,16,195q0,29.25,36.25,29.25,17.5,0,43-8,14.75-5,23.5-14.75,8-9.25,8-18,0-12-14-12-18,0-34.75,10.75-19.25,12.5-19.25,31.25,0,4-2.75,4-2.25,0-2.25-3.5,0-22.5,20-37.25A69.873,69.873,0,0,1,114.25,164q12,0,19.25,5.75a16.841,16.841,0,0,1,6.75,14q0,10-9,20.25-9.75,10.5-27,16.25Q75,229.75,54,229.75Z"
      transform="translate(-8 -73)"
      fill="#fff"
    />
  </svg>
);

export const NumberSeven = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="141.01"
    height="164.75"
    viewBox="0 0 141.01 164.75"
  >
    <path
      d="M43,228.75a3.892,3.892,0,0,1-4-2.5A38.5,38.5,0,0,1,37,214q0-25.5,22.5-57.25A498.378,498.378,0,0,1,128,79.5q-6-1-12-1.75l-12-1.5Q95.5,75,87.625,74.375T72.5,73.75q-22,0-31.75,4.75-13.5,6.25-16,23-.75,4.75-5.5,4.75-6.25,0-6-6.25l.25-1.5V97L15,92.5a39.524,39.524,0,0,0,1.25-4.75Q18.25,81,17.5,79a10.712,10.712,0,0,1-.75-3.75q0-3.25.75-3.25t1.75,2.25a37.152,37.152,0,0,1,1.5,6.5Q34.5,64,65.75,64A100.931,100.931,0,0,1,80,65.25Q88.5,66.5,99.75,69q13.25,2.75,21.375,4.375A112.32,112.32,0,0,0,132.5,75.25l11.25-9.75a1.448,1.448,0,0,1,1-.25q2.25,0,5.75,3.5Q154.25,72,154.25,74a1.448,1.448,0,0,1-.25,1q-25.75,21.5-47.375,42.75T67.5,160a131.082,131.082,0,0,0-15.75,25.5q-8.25,18-8.25,31a30.068,30.068,0,0,0,1.5,10,.509.509,0,0,0,.125.375.509.509,0,0,1,.125.375Q45.25,228.75,43,228.75Z"
      transform="translate(-13.24 -64)"
      fill="#fff"
    />
  </svg>
);
