import { graphql } from 'gatsby';
import { getImage, withArtDirection } from 'gatsby-plugin-image';
import { SectionImgNumbered } from '../components/layout/sections/SectionTwoColsImgNumbered';
import * as N from '../components/vectors/Numbers';
import { PageWrapper } from '../components/layout/PageWrapper';
import { HeroClip } from '../components/layout/sections/SectionHero';
import { AboutTextBox } from '../components/misc/HeroContent';

const Numbers = [
  <N.NumberOne />,
  <N.NumberTwo />,
  <N.NumberThree />,
  <N.NumberFour />,
  <N.NumberFive />,
  <N.NumberSix />,
  <N.NumberSeven />,
];

const AboutPage = ({
  data: {
    datoCmsAbout: {
      seo,
      heroImage: { heroImageData },
      heroImageMedium: { heroImageMediumData },
      heroTitle,
      heroSubtitle,
      section,
    },
  },
  pageContext,
}) => {
  const directedImages = withArtDirection(getImage(heroImageData), [
    {
      media: '(max-width: 810px)',
      image: getImage(heroImageMediumData),
    },
  ]);
  return (
    <PageWrapper
      pageData={pageContext}
      seoTitle={seo?.seoTitle}
      seoDescription={seo?.seoDescription}
      seoImage={seo?.image?.seoImageUrl}
    >
      <HeroClip
        scale
        maxHeight="850px"
        overlay
        $gatsbyImageMaxHeight="800px"
        clipHeight="800px"
        $minHeightMobile="800px"
        clipBackground="var(--blue-2)"
        imageData={directedImages}
        contentBox={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <AboutTextBox
            heroTitle={heroTitle}
            heroSubtitle={heroSubtitle}
            paddingBottom="140px"
          />
        }
      />
      {section.map(
        (
          {
            id,
            sectionTitle,
            sectionDescription,
            sectionImage: { gatsbyImageData },
          },
          index
        ) => (
          <SectionImgNumbered
            indexNumber={index + 1}
            key={id}
            svgNumber={Numbers[index]}
            imageData={gatsbyImageData}
            title={sectionTitle}
            description={sectionDescription}
          />
        )
      )}
    </PageWrapper>
  );
};

export default AboutPage;

export const query = graphql`
  query AboutQuery($locale: String!) {
    datoCmsAbout(locale: { eq: $locale }) {
      seo {
        seoTitle: title
        seoDescription: description
        image {
          seoImageUrl: url
        }
      }
      heroImage {
        heroImageData: gatsbyImageData
      }
      heroImageMedium {
        heroImageMediumData: gatsbyImageData
      }
      heroTitle
      heroSubtitle
      section {
        id: originalId
        sectionTitle
        sectionDescription
        sectionImage {
          gatsbyImageData
        }
      }
    }
  }
`;
