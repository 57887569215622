/* eslint-disable react/jsx-one-expression-per-line */
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import { useIntl } from 'react-intl';
import { isOdd } from '../../../utils/misc';
import { HeadingSection, SubtitleSection } from '../../styles/Typography';

const Wrapper = styled.section`
  overflow: hidden;
  width: 100%;
  background: var(--blue-2);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ indexNumber }) =>
    indexNumber === 7
      ? 'var(--gap-3) var(--padding) 140px var(--padding)'
      : 'var(--gap-3) var(--padding)'};
  margin-bottom: ${({ indexNumber }) => indexNumber === 7 && '-100px'};
  flex-direction: column;
`;

const HeadingContainer = styled.header`
  width: var(--layout-desktop-container);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: var(--gap-3) 0 70px 0;

  @media screen and (max-width: 1240px) {
    width: 100%;
  }
`;

const Container = styled.div`
  width: var(--layout-desktop-container);
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: var(--gap-2);
  align-items: center;
  justify-items: center;

  @media screen and (max-width: 1240px) {
    width: 100%;
    column-gap: var(--gap-3);
  }

  @media screen and (max-width: 810px) {
    grid-template-columns: 1fr;
    row-gap: var(--gap-3);
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: min-content;
`;

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;

  ${({ indexNumber }) =>
    !isOdd(indexNumber)
      ? css`
          justify-content: flex-start;
          grid-column: 2;
          grid-row: 1;
          justify-content: left;
        `
      : css`
          justify-content: flex-end;
        `}

  @media screen and (max-width:  810px) {
    justify-content: center !important;
    grid-column: unset !important;
    grid-row: unset !important;
  }
`;

const NumberContainer = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 100px;
  background: var(--gold-2);
  right: 0;
  top: 10%;
  z-index: 2;
  position: absolute;

  & svg {
    width: 120px;
    height: 120px;
    padding: 0 0 40px 20px;
  }

  @media screen and (max-width: 480px) {
    right: 15%;
    top: 0;
  }
`;

const Image = styled(GatsbyImage)`
  width: 400px;

  & img {
    border-radius: 400px;
    border: 4px solid var(--gold-2);
  }

  @media screen and (max-width: 480px) {
    width: 110vw;
    margin-left: -10vw;
  }
`;

const TextBox = styled.section`
  display: grid;
  row-gap: var(--gap-5);
  height: min-content;

  ${({ indexNumber }) =>
    !isOdd(indexNumber) &&
    css`
      text-align: right;
    `}

  @media screen and (max-width: 810px) {
    text-align: center !important;
    width: calc(100vw - 60px);
    left: 0;
    position: relative;
    justify-items: center;
  }

  @media screen and (max-width: 480px) {
    text-align: left !important;
    left: -20px;
    justify-items: left;
  }
`;

export const SectionImgNumbered = ({
  imageData,
  title,
  description,
  indexNumber,
  svgNumber,
}) => {
  const intl = useIntl();

  return (
    <Wrapper indexNumber={indexNumber}>
      {indexNumber === 1 && (
        <HeadingContainer>
          <HeadingSection fullWidth>
            {intl.formatMessage({ id: 'eachProduct' })}
            <span style={{ color: 'var(--gold-2)' }}> La Rosa dei gusti </span>
            {intl.formatMessage({ id: 'eachProductMust' })}
          </HeadingSection>
        </HeadingContainer>
      )}
      <Container>
        <ImageWrapper indexNumber={indexNumber}>
          <ImageContainer indexNumber={indexNumber}>
            <NumberContainer>{svgNumber}</NumberContainer>
            <Image image={imageData} alt="" />
          </ImageContainer>
        </ImageWrapper>
        <TextBox indexNumber={indexNumber}>
          <HeadingSection goldHeading>{title}</HeadingSection>
          <SubtitleSection gold>{description}</SubtitleSection>
        </TextBox>
      </Container>
    </Wrapper>
  );
};
